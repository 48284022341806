<template>
  <div>
    <a-form :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <!-- <SForm v-for="item in formOption" :itemConfig="item" ref="SForm"></SForm> -->
      <a-form-item
        v-for="item in formOption"
        :key="item.value"
        :label="item.label"
      >
        <!-- 输入 -->
        <a-input
          v-if="item.type === 'input'"
          v-model="form[item.value]"
          :placeholder="'请输入' + item.label"
        ></a-input>
        <!-- 数字输入 min="0"-->
        <a-input-number
          v-if="item.type === 'number'"
          v-model="form[item.value]"
          :placeholder="'请输入' + item.label"
        ></a-input-number>
        <!-- 搜索 -->
        <a-select
          v-if="item.type === 'select'"
          v-model="form[item.value]"
          @change="change()"
          :placeholder="'请选择' + item.label"
        >
          <a-select-option
            v-for="opt in item.options"
            :key="opt.value"
            :value="opt.value"
            >{{ opt.label }}</a-select-option
          >
        </a-select>
        <!-- 远程搜索 -->
        <a-select
          v-if="item.type === 'remoteSearch'"
          show-search
          v-model="form[item.value]"
          :placeholder="'请输入查询' + item.label"
          :default-active-first-option="false"
          :show-arrow="true"
          :filter-option="false"
          :not-found-content="null"
          @search="handleSearch($event, item.method, item.value)"
          @change="handleChange"
        >
          <a-select-option
            v-for="opt in item.options"
            :key="opt.value"
            :value="opt.value"
          >
            {{ opt.label }}
          </a-select-option>
        </a-select>
        <span v-if="item.isMust" style="color: red">*</span>
      </a-form-item>
    </a-form>
    <div style="margin-top: 20px; text-align: right">
      <a-button @click="cancel()">返回</a-button>
      <a-button @click="submit()" type="primary" style="margin-left: 16px"
        >确认</a-button
      >
    </div>
  </div>
</template>
<script>
import { SelectOptions } from "@/config/SelectOptions.js";
import { AddSite, UpdateSite } from "@/api/site";
import { GetCompanies } from "@/api/device";
import { GetWorkers } from "@/api/worker";
// import { SForm } from "@/components";
const addVerify = [
  "ws_only",
  "ws_name",
  "yz_name",
  "yz_phone",
  "bc_id",
  "jl_id",
  "gj_id",
  "service_type",
  "ws_room",
  "ws_hall",
  "ws_kitchen",
  "ws_toilet",
  "ws_balcony",
  "ws_q_area",
];
const editVerify = [
  "ws_id",
  "bc_id",
  "ws_name",
  "jl_id",
  "gj_id",
  "ws_bind_status",
  "ws_status",
  "service_type",
  "ws_only",
  "ws_room",
  "ws_hall",
  "ws_kitchen",
  "ws_toilet",
  "ws_balcony",
  "ws_q_area",
];
const addFormOption = [
  {
    label: "合同编号",
    value: "ws_only",
    type: "input",
    isMust: true,
  },
  {
    label: "工地名称",
    value: "ws_name",
    type: "input",
    isMust: true,
  },
  {
    label: "业主名称",
    value: "yz_name",
    type: "input",
    isMust: true,
  },
  {
    label: "业主电话",
    value: "yz_phone",
    type: "input",
    isMust: true,
  },
  {
    label: "分公司名称", // 表头文字
    value: "bc_id",
    type: "remoteSearch",
    options: [],
    method: "GetCompanies",
    isMust: true,
  },
  {
    label: "项目经理",
    value: "jl_id",
    type: "remoteSearch",
    options: [],
    method: "GetWorkers",
  },
  {
    label: "工程管家",
    value: "gj_id",
    type: "remoteSearch",
    options: [],
    method: "GetWorkers",
  },
  {
    label: "业务类型",
    value: "service_type",
    // type: "select",
    type: "input",
    // options: SelectOptions.SiteServiceType,
  },
  {
    label: "卧室个数",
    value: "ws_room",
    type: "number",
  },
  {
    label: "客厅个数",
    value: "ws_hall",
    type: "number",
  },
  {
    label: "厨房个数",
    value: "ws_kitchen",
    type: "number",
  },
  {
    label: "卫生间个数",
    value: "ws_toilet",
    type: "number",
  },
  {
    label: "阳台个数",
    value: "ws_balcony",
    type: "number",
  },
  {
    label: "房屋面积",
    value: "ws_q_area",
    type: "number",
  },
];
const editFormOption = [
  {
    label: "合同编号",
    value: "ws_only",
    type: "input",
  },
  {
    label: "工地名称",
    value: "ws_name",
    type: "input",
  },
  {
    label: "分公司名称", // 表头文字
    value: "bc_id",
    type: "remoteSearch",
    options: [],
    method: "GetCompanies",
  },

  {
    label: "项目经理",
    value: "jl_id",
    type: "remoteSearch",
    options: [],
    method: "GetWorkers",
  },
  {
    label: "工程管家",
    value: "gj_id",
    type: "remoteSearch",
    options: [],
    method: "GetWorkersGJ",
  },
  {
    label: "业务类型",
    value: "service_type",
    type: "input",
    // options: SelectOptions.SiteServiceType,
  },
  {
    label: "绑定状态",
    value: "ws_bind_status",
    type: "select",
    options: SelectOptions.SiteBindStatus,
  },
  {
    label: "工地状态",
    value: "ws_status",
    type: "select",
    options: SelectOptions.SiteStatus,
  },

  {
    label: "卧室个数",
    value: "ws_room",
    type: "number",
    defaultValue: 0,
  },
  {
    label: "客厅个数",
    value: "ws_hall",
    type: "number",
  },
  {
    label: "厨房个数",
    value: "ws_kitchen",
    type: "number",
  },
  {
    label: "卫生间个数",
    value: "ws_toilet",
    type: "number",
  },
  {
    label: "阳台个数",
    value: "ws_balcony",
    type: "number",
  },
  {
    label: "房屋面积",
    value: "ws_q_area",
    type: "number",
  },
];
export default {
  name: "SiteEdit",
  props: {
    formType: {
      type: String,
      default: "add",
    },
    record: {
      type: Object,
    },
  },
  components: {
    // SForm
  },
  data() {
    return {
      formOption: [],
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {},
      companies: [],
      bc_idtimer: null,
      jl_idtimer: null,
      gj_idtimer: null,
    };
  },
  async created() {
    if (this.formType === "edit") {
      await this.fetchCompany();
      this.form = JSON.parse(JSON.stringify(this.record));
      this.formOption = editFormOption;
      // id需要列表接口中返回
      // this.form.jl_id = 124;
      // this.form.gj_id = 108;
      this.form.jl_id = this.form.w_name_jl_id;
      this.form.gj_id = this.form.w_name_gj_id;
      // console.log(this.form);
      this.form.service_type = this.form.ws_service_type;
      this.form.ws_room = this.form.wsRoom;
      this.form.ws_hall = this.form.wsHall;
      this.form.ws_kitchen = this.form.wsKitchen;
      this.form.ws_toilet = this.form.wsToilet;
      this.form.ws_balcony = this.form.wsBalcony;
      this.form.ws_q_area = this.form.wsQArea;

      this.handleSearch(this.form.bc_id, "GetCompanies", "bc_id");
      this.handleSearch(this.form.w_name_jl, "GetWorkers", "jl_id");
      this.handleSearch(this.form.w_name_gj, "GetWorkersGJ", "gj_id");
    } else {
      this.formOption = addFormOption;
      await this.fetchCompany();
    }
  },
  methods: {
    handleChange(event) {
      console.log(event);
      this.$forceUpdate();
    },
    handleSearch(val, method, key, id) {
      let that = this;
      let validate = async function () {
        let index = null;
        that.formOption.forEach((el, i) => {
          if (el.value === key) {
            index = i;
          }
        });
        if (method === "GetCompanies") {
          let filter = that.companies.filter(
            (el) => el.value == val || el.label.indexOf(val) > -1
          );
          that.formOption[index].options = filter;
        }
        if (method === "GetWorkers") {
          let filter = await that.fetchWorker(val, key);
          that.formOption[index].options = filter;
        }
        if (method === "GetWorkersGJ") {
          let filter = await that.fetchWorker(val, key);
          that.formOption[index].options = filter;
        }
      };
      // 输入防抖
      if (val) {
        this.inputDubounce(validate, 1000, key);
      }
    },
    change() {},
    cancel() {
      this.$emit("emitEdit");
    },
    submit() {
      let req = null;
      if (this.formType === "add") {
        const orderParam = this.$order(this.form, addVerify);
        const params = {
          ...orderParam,
          verify: this.$verify(orderParam, addVerify),
        };
        req = AddSite(params);
      } else {
        const orderParam = this.$order(this.form, editVerify);
        const params = {
          ...orderParam,
          verify: this.$verify(orderParam, editVerify),
        };
        req = UpdateSite(params);
      }
      req.then((res) => {
        console.log(res.data);
        if (res.code == "200") {
          this.$message.success(res.data);
          this.cancel();
        } else if (res.code == "500") {
          this.$message.warning("合同编号、工地名称、分公司名称不能为空");
        } else {
          this.$message.warning(res.data);
        }

        // this.cancel();
      });
    },

    inputDubounce(fun, time, type) {
      if (this[type + "timer"] !== null) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(fun, time);
    },
    async fetchCompany() {
      let data = [];
      await GetCompanies()
        .then((res) => {
          res.data.forEach((el) => {
            data.push({
              label: el.bc_name,
              value: el.bc_id,
            });
          });
        })
        .then(() => {
          this.companies = data;
          // console.log(this.companies, addFormOption);
          // let index = null;
          // addFormOption.forEach((el, i) => {
          //   if (el.value == "bc_id") {
          //     index = i;
          //   }
          // });
          // addFormOption[index].options = data;
        });
    },
    async fetchWorker(val, key) {
      let data = [];
      let params = {
        pageNo: 1,
        pageSize: 100,
        w_name: val.toString().trim(),
        w_post: key === "gj_id" ? "1" : "2",
      };
      await GetWorkers(params)
        .then((res) => {
          res.data.data.forEach((el) => {
            data.push({
              label: el.w_name,
              value: el.w_id,
            });
          });
        })
        .then(() => {
          // let index = null;
          // addFormOption.forEach((el, i) => {
          //   if (el.value == "gj_id") {
          //     index = i;
          //   }
          // });
          // addFormOption[index].options = data;
        })
        .then(() => {});
      return data;
    },
  },
  beforeDestroy() {
    this.bc_idtimer = null;
    this.jl_idtimer = null;
    this.gj_idtimer = null;
  },
};
</script>
<style lang="less" scoped>
/deep/.ant-form-item-children {
  // display: inline-block;
  width: 100%;
  display: flex;
  .ant-input-number {
    width: 100%;
  }
}
</style>